import React, {useState,useEffect, useMemo} from 'react';
import Markdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import { useParams, useNavigate, Link } from 'react-router-dom';
import '../styles/projet.css';


const Projet = () => {
    const {id} = useParams();
    const [error, setError] = useState(null);
    const [markdown, setMarkdown] = useState("");
    const navigate = useNavigate();


    const markdownFiles = useMemo(() => ({
        0: "/pages/welcome.md",
        1: "/pages/whoami.md",
        2: "/pages/site.md",
        3: "/pages/TreasureHunt.md",
        4: "/pages/decac.md",
        5: "/pages/projetC.md",
        6: "/pages/Crf.md",
        7: "/pages/pompier.md",
        8: "/pages/ensishell.md",
        9: "/pages/TIPE.md",

      }),[]);

      const goToNextProject = () => {
        console.log(Object.keys(markdownFiles).length);
        const nextProjectId = (parseInt(id)+1) % Object.keys(markdownFiles).length; // Retourne au premier projet si on est au dernier
        console.log(id);
        console.log(nextProjectId);
        navigate(`/projet/${nextProjectId}`);
      };

      useEffect(() => {

        if (markdownFiles[id]) {
          // Si l'ID existe dans le mapping
          fetch(markdownFiles[id])
          .then((response) => {
              if (!response.ok) {
                throw new Error(`Erreur HTTP : ${response.status}`);
              }
              return response.text();
            })
            .then((text) => setMarkdown(text))
            .catch((err) => {
              setError(`Erreur lors du chargement : ${err.message}`);
            });
          } else {
            // Cas où l'ID n'existe pas dans le mapping
            setError(`Aucun fichier Markdown trouvé pour l'ID : "${id}"`);
          }
      }, [id, markdownFiles]); // Ajoutez `id` comme dépendance pour détecter les changements d'URL
    
      useEffect(() => {
        // Redirection si une erreur est détectée
        if (error) {
          navigate("/"); // Redirige vers la page d'accueil
        }
      }, [error, navigate]);

    return (
        <>
<nav className="flex items-center justify-between text-secondary p-4 shadow-lg">
  {/* Bouton gauche : Retour à l'accueil */}
  <Link
    to="/"
    className="text-sm md:text-base px-3 py-2 rounded-lg flex items-center"
  >
    Accueil
  </Link>

  {/* Boutons droits : Sommaire / Aller au projet suivant */}
  <div className="flex items-center gap-2 ml-auto">
    <Link
      to="/projet/0"
      className="text-sm md:text-base px-3 py-2 rounded-lg flex items-center"
    >
      Sommaire
    </Link>
    <button
      onClick={goToNextProject}
      className="text-sm md:text-base px-3 py-2 rounded-lg flex items-center"
    >
      Suivant ➡️
    </button>
  </div>
</nav>
        <div className='projet-page' >
      {error ? (
        <div style={{ color: "red" }}>{error}</div> // Affiche une erreur si elle existe
      ) : (
        <div className="max-w-[800px] m-auto my-0 p-[20px]">
          <Markdown rehypePlugins={[rehypeRaw]}>{markdown}</Markdown>
        </div>
      )}
    </div>
        </>
    );
};

export default Projet;
