import React, { useState, useEffect } from 'react';
import Loader from './components/Loader';
import MainPage from './components/MainPage';
import CV from './components/CV.js';
import Projects from './components/Projects';
import Projet from './components/Projet.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 46*80 + 3*80 );

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoaded ? (
          <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/CV" element={<CV />} />
            <Route path="/projets" element={<Projects />} />
            <Route path="/projet/:id" element={<Projet />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </Router>
      ) : (
        <>
        <Loader />
        </>
      )}
    </div>
  );
}

export default App;
