const Salut =({ src, alt }) => {

    return (
        <div className="col-span-5 row-span-5 max-lg:col-span-8 max-md:col-span-full">
            <div className="box z-10 flex size-full flex-col justify-between gap-4">
                <div className="ml-auto size-[8rem] transition-transform duration-1000 ease-out hover:rotate-180">
                    <img alt="flower" loading="lazy" width="100" height="100" decoding="async" className="intro-icon size-full" src="/flower.svg" />
                </div>
                <h1 className="intro-heading max-w-[35rem] leading-[130%] my-1 pb-8 text-[3.6rem] font-heading font-normal" >Salut <span className="accent">!</span> <br /> C'est <span className="italic">Raph<span className='accent'>'</span></span></h1>
            </div>
    </div>
    );
};

export default Salut;