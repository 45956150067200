

import React from 'react';

const Description = ({ src, alt }) => {
    return (

        <div className="box col-span-4 row-span-4 max-lg:col-span-6 max-lg:min-h-[20rem] max-md:col-span-full">
            <div className="box">
                <div className="flex size-full flex-col justify-between gap-4">
                    <div className="size-[2.75rem] -rotate-180 transition-transform duration-1000 ease-out hover:rotate-180">
                        <img alt="disk" loading="lazy" width="48" height="48" decoding="async" className="about-icon size-full" src="/round.svg" />
                    </div>
                    <p className="about-text max-w-[25rem] pb-2 text-4xl leading-[135%] font-DM">
                        Je suis un ingénieur informatique passioné par la cybersécurité
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Description;