

import React from 'react';

const Contact = ({ src, alt }) => {
    return (

        <div className="col-span-4 row-span-1 max-lg:col-span-full max-lg:min-h-[5rem]">
            <div className="box">
                <div className="flex size-full items-center justify-evenly gap-2 font-DM text-xl">
                    <a href="https://www.linkedin.com/in/raphael-euzeby/" target="_blank" rel="noopener noreferrer" className="flex overflow font-medium uppercase leading-[100%]">
                        <span className="social-link inline-block">
                            Linkedin</span>
                    </a>
                    <a href="mailto:r4ph3uz-contact@yahoo.com" target="_blank" rel="noopener noreferrer" className="flex overflow font-medium uppercase leading-[100%]">
                        <span className="social-link inline-block">
                            Mail</span>
                    </a>
                    <a href="tel:+33123456789" target="_blank" rel="noopener noreferrer" className="flex overflow font-medium uppercase leading-[100%]">
                        <span className="social-link inline-block">
                            Telephone</span>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Contact;
