import React, { useEffect, useRef } from 'react';
import '../styles/Loader.css';

const Loader = () => {
    const loaderRef = useRef(null);
    const desWrapRef = useRef(null);

    useEffect(() => {
        const children = desWrapRef.current.children;
        const len = children.length;
        const loader = loaderRef.current;

        for (let i = 5; i >= 0; i--) {
            setTimeout(() => {
                children[i].style.opacity = 1;
                children[len - i - 1].style.opacity = 1;
            }, 80 * (len - i));
        }

        for (let i = 5; i >= 0; i--) {
            setTimeout(() => {
                children[i].style.opacity = 0;
                children[len - i - 1].style.opacity = 0;
            }, 80 * (len - i + 6));
        }

        for (let i = 0; i <= 5; i++) {
            setTimeout(() => {
                children[i].style.opacity = 1;
                children[len - i - 1].style.opacity = 1;
            }, 80 * (len + len + i));
        }

        for (let i = 0; i < 5; i++) {
            setTimeout(() => {
                children[i].style.opacity = 0;
                children[len - i - 1].style.opacity = 0;
            }, 80 * (len + len + 6 + i));
        }

        setTimeout(() => {
            loader.classList.add("loading--change");
        }, 33 * 80);

        setTimeout(() => {
            loader.classList.add("loading--ending");
        }, 36 * 80);

        setTimeout(() => {
            loader.classList.add("loading--end");
        }, 46 * 80);
    }, []);

    return (
        <section className="loader" ref={loaderRef}>
            <div className="loader-wrap">
                <div className="des-wrap" ref={desWrapRef}>
                    <span className="des des--top">
                        <span className="des-inner des-inner--stroke">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--top">
                        <span className="des-inner">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--top">
                        <span className="des-inner des-inner--stroke">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--top">
                        <span className="des-inner des-inner--stroke">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des">
                        <span className="des-inner des-inner--top">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--full">
                        <span className="des-inner">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des">
                        <span className="des-inner des-inner--bottom">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--bottom">
                        <span className="des-inner des-inner--stroke">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--bottom">
                        <span className="des-inner des-inner--stroke">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--bottom">
                        <span className="des-inner">Mens&nbsp;&nbsp;sana&nbsp;&nbsp;in&nbsp;&nbsp;corpore&nbsp;&nbsp;sano</span>
                    </span>
                    <span className="des des--bottom">
                        <span className="des-inner des-inner--stroke">Mens&nbsp; sana&nbsp; in&nbsp; corpore&nbsp; sano</span>
                    </span>
                </div>
            </div>
        </section>
    );
};

export default Loader;
