const Photo = ({ src, alt }) => {

    return (
        <div className={`animate-appearbox transition-all duration-[1000ms] ease-in-out col-span-3 row-span-5 max-lg:col-span-4 max-md:col-span-full z-20 relative"`}>
            <div className="size-full">
                <div
                    className="postloader rounded-[16px] aspect-square h-full w-full bg-secondary"
                    data-flip-id="postloader"
                >
                    <img
                        alt={alt}
                        fetchPriority="high"
                        decoding="async"
                        data-nimg="fill"
                        className="h-full w-full object-cover object-top rounded-[16px]"
                        sizes="100vw"
                        srcSet={`${src} 1x, ${src.replace('.png', '@2x.png')} 2x`}
                        src={src}
                    />
                </div>
            </div>
        </div>
    );
};

export default Photo;
