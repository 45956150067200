import React, { useState, useEffect } from 'react';
import Tile from './Tile.js'

const Column = ({ tiles, offset }) => {
  // Tiles mélangées

  const [currentIndex, setCurrentIndex] = useState(-1); // L'index de la tuile actuellement affichée
  
  useEffect(() => {
    // Démarrer le défilement après un délai initial (offset)
    const startTimeout = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % tiles.length);
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % tiles.length);
      }, 5000);
      return () => clearInterval(interval);
    }, offset);

    // Nettoyer le timeout si le composant est démonté avant qu'il commence
    return () => clearTimeout(startTimeout);
  }, [tiles.length, offset]);  
  
  return (
    <div className={`z-20 w-full md:max-h-[100vw] md:h-[100vh] relative px-[5px] flex-[0_0_25%] flex-shrink-0 flex flex-col justify-center align-middle`}>
            {tiles.map((tile, index) => (
            <Tile key={index} visible={index === currentIndex} {...tile.props} />
            ))}
    </div>
  );
};

export default Column;
