import React, { useEffect, useState } from 'react';
import '../styles/button.css';
import { useNavigate } from 'react-router-dom';
import Column from './Column';
import Tile from './Tile';

const Projects = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    // Empêche le comportement par défaut du lien
    e.preventDefault();

    const context = require.context('../../public/pages', false, /\.md$/); // Récupère tous les fichiers dans /pages/projet
    const projectFiles = context.keys(); // Liste des fichiers
    const projectCount = projectFiles.length;

    // Générer un numéro de fichier aléatoire
    const randomProjectId = Math.floor(Math.random() * projectCount)

    // Créez une URL dynamique pour rediriger vers un projet aléatoire
    const projectUrl = `/projet/${randomProjectId}`;

    // Utiliser navigate pour rediriger vers l'URL du projet
    navigate(projectUrl);
  };
  const shuffle = (tiles) => {
    return [...tiles].sort(() => Math.random() - 0.5);
  };


  var [shuffledTiles, setShuffledTiles] = useState([
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/whoami.png',
        link: '/projet/1',
        altText: "Image de mario codant devant un pc",
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/home_falling.png',
        link: '/projet/2',
        altText: 'Image de la page d explication de la creation de ce site',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/michelin_crop.png',
        link: '/projet/3',
        altText: 'Carmes, Clermont Ferrand, Site michelin',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/readme_pgl.png',
        link: '/projet/4',
        altText: 'Image du readme du compilateur deca',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/taquin.png',
        link: '/projet/5',
        altText: 'Image d un jeu créé pendant le projet C',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/moi_logo_crf.jpg',
        link: '/projet/6',
        altText: 'Fier devant le logo de la CRF de Grenoble',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/pompier_falling.png',
        link: '/projet/7',
        altText: 'Placeholder Image avant dernière',
      },
    },
    {
      Component: Tile,
      props: {
        imageSrc: '/pictures/ensishell_falling.png',
        link: '/projet/8',
        altText: 'Utilisation de ensishell',
      },
    },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image 3 en partant de la fin',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image avant dernière',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/gratteciel.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image dernière',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image 3 en partant de la fin',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image avant dernière',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/gratteciel.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image dernière',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image 3 en partant de la fin',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/mountain.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image avant dernière',
    //   },
    // },
    // {
    //   Component: Tile,
    //   props: {
    //     imageSrc: '/pictures/gratteciel.jpg',
    //     link: '/projet/',
    //     altText: 'Placeholder Image dernière',
    //   },
    // },

  ]);


  useEffect(() => {
    setShuffledTiles(prevTiles => shuffle(prevTiles));
  }, []);

  return (
    <>
      <div className="relative h-screen  min-h-[800px] flex flex-col lg:flex-row items-center justify-center overflow-hidden cursor-default">
        {/* Texte principal */}
        <div className="relative text-secondary font-bold text-[11vw] sm:text-[13vw] md:text-[14vw] lg:text-[12vw] xl:text-[11vw] leading-none text-center tracking-tight min-h-screen flex flex-col lg:flex-col items-center justify-center overflow-hidden">
          <div className="z-30">
            <p><span>Voir</span></p>
          </div>
          <div className='z-10'>
            <p>
              <span >tous</span> <span>mes</span>
            </p>
          </div>
          <div className="z-30">
            <p><span>projets</span></p>
          </div>
        </div>


        {/* Bouton */}
        <div className="absolute bottom-5 left-5 sm:bottom-10 sm:left-10 sm:w- z-30">
          <button
            onClick={handleClick}
            className="m-auto w-[90vw] md:m-0 md:w-auto hasard text-sm sm:text-xs md:text-lg px-4 py-6 text-secondary rounded hover:bg-primary-dark transition"
          >
            Projet au hasard
          </button>
        </div>
      </div>

      <div className="absolute top-0 left-0 w-full h-full grid grid-rows-3 md:grid-cols-4 gap-3 overflow-hidden">
        
        <Column tiles={shuffledTiles.slice(0, shuffledTiles.length / 4)} offset={0}></Column>
        <Column tiles={shuffledTiles.slice(shuffledTiles.length / 4, shuffledTiles.length / 2)} offset={2300}></Column>
        <Column tiles={shuffledTiles.slice(shuffledTiles.length / 2, 3 * shuffledTiles.length / 4)} offset={1100}></Column>
        <div className="hidden md:block">
        <Column tiles={shuffledTiles.slice(3 * shuffledTiles.length / 4, shuffledTiles.length + 1)} offset={3100}></Column>
        </div>

      </div>

    </>
  );
};

export default Projects;
