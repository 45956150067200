import React from 'react';
import {Link} from 'react-router-dom';

const Projets = ({ src, alt }) => (
    <div className="col-span-4 row-span-8 max-lg:col-span-full">
        <div className="box py-0">
            <div className="relative z-10 size-full overflow-hidden">
                <div className="hide-scrollbar flex h-full flex-col overflow-y-auto max-lg:overflow-y-visible">
                    <button className="group pointer-events-none button relative pb-[calc(1.8rem-2px)] pt-[1.8rem]">
                        <div className="flex w-full items-baseline justify-between">
                            <Link to="/projets" className="pointer-events-auto inline-block px-2">
                                <h3 className="overflow-hidden font-heading text-2xl font-normal leading-[100%]">
                                    <span className="work-heading block pb-[2px]">
                                        Mes Projets 🚀</span>
                                </h3>
                            </Link>
                            <Link to="/projets" className="pointer-events-auto inline-block px-2">
                                <img alt="arrow" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" className="opacity-1 arrow size-[0.9rem] group-hover:animate-moveArrow" src="/arrow.svg" />
                            </Link>
                        </div>
                        <Link to="/projets" className=" mt-4 h-auto thumbnail pointer-events-auto relative block aspect-[3/2] w-full origin-top overflow-hidden rounded-[16px] bg-secondary">
                            <img alt="Alternative text" fetchPriority="high" decoding="async" data-nimg="fill" className="object-cover transition-transform duration-700 ease-out hover:scale-105" sizes="100vw" srcSet="" src={src} />
                        </Link>
                        <div className="work-border absolute bottom-0 left-0 h-[1px] w-full origin-left bg-secondary">
                        </div>
                    </button>
                    <button className="pointer-events-auto button relative pb-[calc(1.8rem-2px)] pt-[1.8rem] group">
                        <div className="flex w-full items-baseline justify-between">
                            <Link to="/cv.pdf" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">
                                <h3 className="overflow-hidden font-heading text-2xl font-normal leading-[100%]">
                                    <span className="work-heading block pb-[2px]">
                                        Mon CV</span>
                                </h3>
                            </Link>
                            <Link to="/cv.pdf" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">
                                <img alt="arrow" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" className="group-hover:animate-moveArrow arrow size-[0.9rem]" src="/arrow.svg" />
                            </Link>

                        </div>
                        <div className="work-border absolute bottom-0 left-0 h-[1px] w-full origin-left bg-secondary">
                        </div>
                    </button>
                    <button className="pointer-events-auto button relative pb-[calc(1.8rem-2px)] pt-[1.8rem] group">
                        <div className="flex w-full items-baseline justify-between">
                            <a href="https://root-me.org/R4ph3uz" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">
                                <h3 className="overflow-hidden font-heading text-2xl font-normal leading-[100%]">
                                    <span className="work-heading block pb-[2px]">
                                        Mon Root - me</span>
                                </h3>
                            </a>
                            <a href="https://root-me.org/R4ph3uz" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">

                                <img alt="arrow" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" className="group-hover:animate-moveArrow arrow size-[0.9rem]" src="/arrow.svg" />
                            </a>
                        </div>
                        <div className="work-border absolute bottom-0 left-0 h-[1px] w-full origin-left bg-secondary">
                        </div>
                    </button>
                    <button className="pointer-events-auto button relative pb-[calc(1.8rem-2px)] pt-[1.8rem] group">
                        <div className="flex w-full items-baseline justify-between">
                            <a href="https://github.com/R4ph3uz" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">
                                <h3 className="overflow-hidden font-heading text-2xl font-normal leading-[100%]">
                                    <span className="work-heading block pb-[2px]">
                                        Mon Github</span>
                                </h3>
                            </a>
                            <a href="https://github.com/R4ph3uz" target="_blank" rel="noopener noreferrer" className="pointer-events-auto inline-block px-2">

                                <img alt="arrow" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" className="group-hover:animate-moveArrow arrow size-[0.9rem]" src="/arrow.svg" />
                            </a>
                        </div>
                        <div className="work-border absolute bottom-0 left-0 h-[1px] w-full origin-left bg-secondary">
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>



);

export default Projets;