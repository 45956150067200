import React from 'react';
import { Link } from 'react-router-dom';

const Tile = ({ imageSrc, link, altText, visible, key }) => (
    <>
        <div className={`z-20 w-full h-full md:h-auto max-h-[80vw] md:max-w-[400px] m-auto ${visible ? 'animate-slideLeftRight md:animate-slideBottomUp' : 'hidden'}`}>
            <p>{key}</p>
            <Link to={link} rel="noopener noreferrer">
                <img
                    src={imageSrc}
                    alt={altText}
                    className="m-auto shadow-black-image md:w-full md:max-w-[400px] h-full md:h-auto"
                />
            </Link>
        </div>

    </>
);

export default Tile;
