import React from 'react';
import Navbar from "./Navbar.js";
import Salut from "./Salut.js";
import Photo from "./Photo.js";
import MoreTel from "./MoreTel.js";
import MorePC from "./MorePC.js";
import Contact from "./Contact.js";
import Description from "./Description.js";
import Projets from "./Projets.js";
import '../styles/MainPage.css';

const MainPage = () => (
    <main className="w-full">
        <div className='grid h-screen max-h-[75rem] min-h-[50rem] grid-cols-12 grid-rows-10 gap-4 p-4 max-lg:h-auto max-lg:max-h-none max-lg:grid-rows-none max-lg:py-6'>

            <Navbar />


            <div className="col-span-8 row-span-9 grid grid-cols-subgrid grid-rows-subgrid max-lg:col-span-full max-lg:grid-rows-none max-lg:gap-4">

                <Salut/>
                <Photo src={"/Raph2.png"} alt={"Raphael"}/> 
                <Description/>
                <MorePC/>
            </div>

            <div className="col-span-4 row-span-9 grid grid-cols-subgrid grid-rows-subgrid max-lg:col-span-full max-lg:grid-rows-none max-lg:gap-4">
                
                <Projets src={"/carbon.png"}/>
                <MoreTel/>
                <Contact/>
            </div>
            
            

        </div>
    </main>

);

export default MainPage;