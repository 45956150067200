
import React from 'react';
import {Link} from 'react-router-dom';


const MoreTel = ({ src, alt }) => {
    return (

        <div className="col-span-4 row-span-4 max-lg:col-span-6 max-lg:min-h-[20rem] max-md:hidden">
            <div className="box bg-secondary group">
                <div className="size-full">
                    <Link to="/projets" className="flex h-full w-full flex-col justify-between gap-4">
                        <div className="contact-bar flex items-center justify-between">
                            <p className="max-w-[10ch] leading-[120%] font-DM text-4xl">
                                Vous voulez en</p>
                            <img alt="arrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" className="arrow mr-2 size-[1.35rem] group-hover:animate-moveArrow" src="/arrow.svg"/>
                        </div>
                        <h2 className="contact-heading pb-2 font-heading text-[3.2rem] font-normal leading-[100%]">
                            Voir <span className="italic">plus</span> <span className='accent'>?</span>
                        </h2>
                    </Link>
                </div>
            </div>
        </div>

    )
};

export default MoreTel;



