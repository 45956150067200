import React from 'react';

const Navbar = () => (
    <div className="col-span-full row-span-1">
        <div className="box" >
            <nav className="flex size-full items-center justify-between max-lg:gap-4 max-md:flex-col max-md:justify-center">
                <div className="overflow-hidden font-heading text-2xl font-normal font-Akira tracking-wider">
                    <span className="block">R4ph3uz</span>
                </div>
                <div className="cta flex items-center gap-4">
                    <a href="mailto:r4ph3uz-contact@yahoo.com" rel="noopener noreferrer" className="inline-block">
                        <button className="rounded-xl px-4 py-3 pt-3.5 font-medium uppercase font-DM text-2xl">Contact</button>
                    </a>
                </div>
            </nav>
        </div>
    </div>
);

export default Navbar;